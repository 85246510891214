@import "@/styles/mixins";

:import("@/components/ui/Scrollable/styles.module.scss") {
  scrollable__scroller: scrollable__scroller;
}

.tabs {
  flex: 1 0 auto;

  &__list {
    overflow: hidden; white-space: nowrap; display: flex;

    > LI {
      display: block; flex: 0 0 auto;

      A {
        text-decoration: none; color: var(--color-text);
        display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; gap: var(--gridunit-tiny);

        SPAN { display: block; }

        &:hover { opacity: 0.7; }

        &[disabled],
        &[aria-disabled="true"] { opacity: 0.5; pointer-events: none; cursor: default; }
      }

      path { fill: currentColor; }
    }

    &__wrapper { display: block; flex: 0 0 auto; }

    &__title { display: block; }

    &__description {
      display: block;

      &.intent-danger { color: var(--color-danger); }

      &.intent-success { color: var(--color-success); }

      &.intent-warning { color: var(--color-warning); }
    }
  }

  &__scroller { margin-top: 0; }

  // Themes

  &.theme-default {
    box-shadow: inset 0 -2px 0 0 var(--color-neutral-800); text-align: center; @include default-margin;
  }

  &.theme-default & {
    &__list {
      > LI {
        @include not-mobile { min-width: 150px; }

        A {
          padding: var(--gridunit-small);

          &[aria-current]:not([aria-current="false"]) { color: var(--color-link); box-shadow: inset 0 -6px 0 0 currentColor; }
        }
      }
    }
  }

  &.theme-small { margin-top: var(--gridunit-medium); }

  &.theme-small & {
    &__list {
      align-items: flex-start; justify-content: flex-start; gap: 12px;

      &__title { padding: 4px 12px; box-shadow: inset 0 0 0 1px var(--color-text); text-align: center; }

      &__description { margin: 4px 0 0 0; }

      A[aria-current]:not([aria-current="false"]) .tabs__list__title { background-color: var(--color-text); color: var(--color-white); }
    }
  }

  &.theme-small.stretch & {
    &__list {
      justify-content: stretch;

      > LI { flex: 1 0 auto; }
    }
  }

  &.theme-well { text-align: center; background-color: var(--color-neutral-100); font-size: 18px; line-height: 26px; }

  &.theme-well & {
    &__list {
      > LI {
        flex: 1 1 0; min-width: 80px;

        A {
          display: block; padding: var(--gridunit-small);

          &[aria-current]:not([aria-current="false"]) { background-color: var(--color-white); color: var(--color-link); }
        }
      }
    }
  }

  // Layouts

  &.layout-default & {
    &__list { flex-direction: row; flex-wrap: nowrap; }
  }

  &.layout-vertical & {
    &__list { flex-direction: row; flex-wrap: wrap; }
  }

  &.layout-grid & {
    &__list { flex-direction: row; flex-wrap: wrap; }
  }
}

.has-arrows {
  @include not-desktop { margin-left: calc(var(--gridunit-small) * -1); margin-right: calc(var(--gridunit-small) * -1); }

  .scrollable__scroller { padding-left: 0 !important; padding-right: 0 !important; }
}
