@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__element: button__element;
  button__inner: button__inner;
}

:import("@/components/ui/SearchField/styles.module.scss") {
  search-field: search-field;
  search-field__icon: search-field__icon;
  search-field__loader: search-field__loader;
  search-field__form: search-field__form;
  search-field__clear: search-field__clear;
  search-field__results: search-field__results;
  focus: focus;
}

.intro {
  page-break-inside: avoid;

  H1 {
    text-align: center; font-size: 60px; line-height: 78px; grid-column: article !important; margin: 0;

    @include mobile { font-size: 40px; line-height: 52px; margin-top: var(--gridunit-medium); }
  }

  .search-field {
    margin: var(--gridunit-large) auto 0 auto; max-width: 800px; width: 100%;

    @include mobile { margin-top: var(--gridunit); }

    &__icon,
    &__loader { display: none; }

    &__form {
      color: var(--color-black);

      INPUT { color: currentColor; padding: 12px 24px; line-height: 32px; height: auto; }

      &:before { background-color: var(--color-primary-50); border-radius: 28px; box-shadow: none; }

      .button {
        margin: 4px; flex: 0 0 auto; display: block;

        &__inner { height: 48px !important; line-height: 48px !important; text-transform: uppercase; border-radius: 24px !important; padding: 0 24px !important; }
      }
    }

    &__clear { width: 56px; height: 56px; }

    &__results:has(> *:nth-child(2)) {
      @include not-mobile { grid-template-columns: 1fr 1fr; }
    }
  }

  &__icon {
    position: relative; pointer-events: none;

    > svg {
      position: absolute; left: var(--gridunit-large); top: var(--gridunit-large);
    }
    @include not-desktop { display: none; }
  }

  &__decorations {
    padding-bottom: 0 !important; position: relative; margin-top: -1px !important;

    &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; height: 50%; background-color: var(--color-primary-850); z-index: 1; }

    &__inner { position: relative; z-index: 2; }

    &__carousel {
      position: absolute !important; left: 0; top: 50%; z-index: 2; transform: translate(0, -50%);

      @include not-desktop { display: none; }
    }

    &__animation {
      position: absolute; right: 0; bottom: 50%; margin-bottom: var(--gridunit); z-index: 2;
    }

    &__image {
      position: relative; width: 100%; max-width: 780px;
      margin: auto; box-sizing: border-box; aspect-ratio: 790 / 450; border-radius: var(--radius-large); overflow: hidden;

      IMG { object-fit: cover; object-position: 50% 50%; }
    }

    @include mobile {
      &__image { aspect-ratio: 330 / 250; }

      &__animation { bottom: 0; margin: 0 20px 0 0; }
    }
  }

  @include not-mobile {
    padding-bottom: 0 !important;
  }
}