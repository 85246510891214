@import "@/styles/mixins";

.list-categories {
  @include default-margin;

  > LI {
    display: grid; grid-template-areas: "icon image" "content image"; column-gap: var(--gridunit-large); row-gap: var(--gridunit-medium); grid-template-columns: 1fr 488px; grid-template-rows: 1fr auto;
    position: relative; z-index: 0;

    + LI { border-top: 1px solid var(--color-neutral-200); margin-top: calc(var(--gridunit) * 2); padding-top: calc(var(--gridunit) * 2); }
  }

  &__icon {
    grid-area: icon; display: flex; z-index: 1;

    IMG,
    svg { width: 112px; height: 112px; }
  }

  &__image {
    grid-area: image; position: relative; aspect-ratio: 488 / 366; overflow: hidden; border-radius: var(--radius-large);

    IMG { object-fit: cover; object-position: 50% 0; }
  }

  &__content { grid-area: content; }

  &__button { margin-top: var(--gridunit-medium); display: flex; }

  &__title { font-size: 40px; line-height: 52px; font-weight: var(--medium); text-wrap: balance; }

  &__description {
    margin-top: var(--gridunit-tiny); font-size: 20px; line-height: 28px; text-wrap: balance;

    P:first-child { margin-top: 0; }
  }

  @include mobile {
    > LI {
      grid-template-areas: "icon image" "content content"; grid-template-columns: 0 1fr;

      + LI { margin-top: calc(var(--gridunit-medium) * 2); padding-top: calc(var(--gridunit-medium) * 2); }
    }

    &__icon { position: absolute; left: 0; top: 0; }

    &__button { align-items: center; justify-content: center; }

    &__title { font-size: 32px; line-height: 42px; }

    &__description { font-size: 16px; line-height: 24px; }
  }

  @include tablet {
    > LI { grid-template-columns: 1fr 1fr; }
  }
}