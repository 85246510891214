@import "@/styles/mixins";

:import("@/components/ui/Well/styles.module.scss") {
  well__wrapper: well__wrapper;
}

:import("@/components/ui/Animations/styles.module.scss") {
  animations: animations;
}

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__inner: button__inner;
}

:import("@/components/ui/HeadingWithTools/styles.module.scss") {
  heading-with-tools: heading-with-tools;
  heading-with-tools__description: heading-with-tools__description;
}

.certificate-search {
  H2 { margin: 0; font-size: 24px; line-height: 1.333; color: var(--color-primary-500); }

  &__field {
    $size: 56px;

    position: relative; margin-top: var(--gridunit-small);

    &__header {
      flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small);
    }

    &__form {
      flex: 1 1 auto; color: currentColor; border-radius: calc($size / 2); cursor: text; position: relative; z-index: 0;
      display: flex; flex-direction: row; flex-wrap: nowrap;

      &:before {
        content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: -1;
        border-radius: calc($size / 2); pointer-events: none; background-color: var(--color-white);
      }

      INPUT {
        flex: 1 1 auto;
        border: none; appearance: none; box-shadow: none; outline: none; box-sizing: border-box; border-radius: 0;
        height: $size; line-height: $size; padding: 0 calc($size / 2); margin: 0; width: 100%; min-width: 150px; background-color: transparent; color: currentColor;

        &:focus { box-shadow: none; outline: none; }

        &::placeholder { color: currentColor; }

        &::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }

      }

      &.focus {
        color: var(--color-text) !important;

        &:before { background-color: var(--color-white); box-shadow: none; }
      }

      .button {
        flex: 0 0 auto;

        &__inner { border: 4px solid var(--color-white); box-sizing: border-box; }
      }
    }

    &:has(&__results) &__form:before { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

    &__results {
      position: absolute; left: 0; right: 0; top: 100%; z-index: 8;
      background-color: var(--color-white); color: var(--color-black); border-radius: 0 0 28px 28px; box-shadow: 0 4px 16px 0 #0216311F;
      padding: var(--gridunit-small);
      overflow: hidden; @include overflow; max-height: clamp(200px, 50vh, 400px);
      display: grid; column-gap: var(--gridunit); grid-template-columns: 1fr; grid-auto-rows: auto;

      &__list {
        > LI {
          A {
            display: block; padding: 4px 8px; box-sizing: border-box; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; text-decoration: none; border-radius: var(--radius-small);

            &.highlight,
            &:hover {
              background-color: var(--color-primary-50);
            }
          }
        }
      }
    }

    @include mobile {
      flex: 1 1 auto; display: flex; flex-direction: column;
    }
    @include not-mobile {
      position: relative; flex: 1 1 auto;
    }

    &.is-overlay {
      background-color: var(--color-white); display: flex; flex: 1 1 auto; min-height: 100%;

      @include not-mobile { flex-direction: column; margin: 100px auto; min-height: 0; }
    }

    &.is-overlay & {
      &__header {
        background-color: var(--color-neutral-50); color: var(--color-black); padding: 13px;

        &__close { display: block; }
      }

      &__clear {
        width: 24px !important; height: 24px !important; flex: 0 0 24px; background-color: var(--color-link); border-radius: 50%; align-self: center;

        svg { width: 16px; height: 16px; color: var(--color-white); }
      }

      &__form {
        &:before { border-radius: 0; background-color: transparent; border-bottom: 1px solid var(--color-neutral-200); box-shadow: none; }
      }

      &__results {
        position: relative; flex: 1 1 auto; overflow: hidden; overflow-y: auto; @include scrollbars; box-shadow: none; border-radius: 0; max-height: none; left: 0; right: 0; top: 0; bottom: 0;
        display: block;
      }
    }
  }

  &__description {
    color: var(--color-primary-500);

    UL:not([class]) {
      padding: 0;

      > LI {
        padding-left: 16px; margin: 0;

        &:before { left: 0; }
      }
    }
  }

  &__info {
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-medium); align-items: flex-start; margin-top: var(--gridunit-medium);

    &__badges {
      flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small);

      > LI {
        position: relative; width: 96px; height: 96px;

        IMG { object-fit: contain; object-position: 50% 50%; }
      }
    }

    &__text {
      flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis;

      P { margin-top: var(--gridunit-tiny); }

      P:first-child { margin-top: 0; }

      @include desktop { border-left: 1px solid var(--color-neutral-800); padding-left: var(--gridunit-medium); }
    }

    @include not-desktop { flex-direction: column; }
  }

  .heading-with-tools {
    margin: 0;

    &__description { margin: 0; font-size: 16px; line-height: 1.333; }

    @include mobile {
      padding-top: 50px;
    }
  }

  .animations {
    @include desktop { margin: -80% 0 -20px 0; }
  }
}

