@import "@/styles/mixins";

.paginator {
  display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center; margin-top: var(--gridunit); gap: 0;

  A {
    display: block; min-width: 44px; line-height: 44px; padding: 0; text-align: center; text-decoration: none; box-sizing: border-box; border-radius: 22px;
    cursor: pointer; color: currentColor; font-size: rem(20);

    &[aria-current="page"] { background-color: var(--color-primary-50); color: currentColor; pointer-events: none; }

    &[aria-disabled="true"] { opacity: 0.5; pointer-events: none; }

    &:hover { background-color: var(--color-primary-100); }

    &.arrow {
      position: relative; width: 44px; height: 44px;

      SPAN { display: none; }

      &:before {
        content: ""; width: 10px; height: 10px;
        position: absolute; left: 50%; top: 50%;
        border: solid currentColor; border-width: 2px 2px 0 0; transform-origin: 50% 50%; box-sizing: border-box;
      }

      &:after { content: ""; width: 14px; height: 2px; position: absolute; left: 50%; top: 50%; background: currentColor; }

      &.arrow-left:before { transform: translate(-50%, -50%) rotate(-135deg); margin: 0 0 0 -1px; }

      &.arrow-left:after { margin: -1px 0 0 -6px; }

      &.arrow-right:before { transform: translate(-50%, -50%) rotate(45deg); margin: 0 0 0 1px; }

      &.arrow-right:after { margin: -1px 0 0 -6px; }
    }
  }

  @media print {
    display: none !important;
  }
}
