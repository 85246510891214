@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
}

:import("@/components/ui/LoadingContent/styles.module.scss") {
  loading-content: loading-content;
  loading-content__gradient: loading-content__gradient;
  loading-content__fill: loading-content__fill;
  loading-content__circle: loading-content__circle;
  loading-content__elem: loading-content__elem;
  loading-content__group: loading-content__group;
}

.search-field {
  $size: 40px;

  position: relative;

  &__header {
    flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small);

    .button { display: none; }

    &__close {
      display: none; width: 40px; height: 40px; border: none; appearance: none; background-color: transparent; position: relative; color: currentColor; cursor: pointer; box-shadow: none;

      &:hover { color: var(--color-link); }

      svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

      path { fill: currentColor; }
    }
  }

  &__form {
    flex: 1 1 auto; color: currentColor; border-radius: 20px; cursor: text; position: relative; z-index: 0;
    display: flex; flex-direction: row; flex-wrap: nowrap;

    &:before {
      content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: -1;
      border-radius: 20px; pointer-events: none; box-shadow: inset 0 0 0 1px var(--color-neutral-400);
    }

    INPUT {
      flex: 1 1 auto;
      border: none; appearance: none; box-shadow: none; outline: none; box-sizing: border-box; border-radius: 0;
      height: $size; line-height: $size; padding: 0; margin: 0; width: 100%; min-width: 150px; background-color: transparent; color: currentColor;

      &:focus { box-shadow: none; outline: none; }

      &::placeholder { color: currentColor; }

      &::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }

    }

    &.focus {
      color: var(--color-text) !important;

      &:before { background-color: var(--color-white); box-shadow: none; }
    }

    &:not(.focus) > .loading-content {
      $c1: rgba(#000000, 0.5);
      $c2: var(--color-white);

      mix-blend-mode: lighten; color: var(--color-white); background-color: var(--header-bg);

      svg { mix-blend-mode: lighten; width: 16px !important; height: 16px !important; }

      .loading-content__gradient { background: conic-gradient(from 270deg at 50% 50%, $c1 0deg, $c1 90deg, $c2 180deg, $c1 190deg, $c1, 270deg, $c1); }

      .loading-content__fill { fill: var(--color-black); }

      .loading-content__circle { stroke: var(--color-white); }

      .loading-content__elem { stroke: var(--color-white); }

      .loading-content__group { mix-blend-mode: darken; }
    }
  }

  &:has(&__results) &__form:before { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

  &__icon {
    flex: 0 0 $size; width: $size; height: $size; position: relative; pointer-events: none;

    svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

    path { fill: currentColor; }
  }

  &__loader {
    flex: 0 0 calc($size - 4px) !important; width: calc($size - 4px) !important; height: calc($size - 4px) !important; position: relative !important; margin: 2px; border-radius: 50%;

    svg { width: 16px !important; height: 16px !important; }
  }

  &__clear {
    flex: 0 0 $size; width: $size; height: $size; position: relative;
    appearance: none; border: none; padding: 0; margin: 0; background: transparent; cursor: pointer;

    svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

    path { fill: currentColor; }

    &[disabled] { opacity: 0; pointer-events: none; }
  }

  &__results {
    position: absolute; left: 0; right: 0; top: 100%; z-index: 8;
    background-color: var(--color-white); color: var(--color-black); border-radius: 0 0 28px 28px; box-shadow: 0 4px 16px 0 #0216311F;
    padding: 0 var(--gridunit-medium) var(--gridunit-medium) var(--gridunit-medium);
    overflow: hidden; @include overflow; max-height: clamp(200px, 50vh, 400px);
    display: grid; column-gap: var(--gridunit); grid-template-columns: 1fr; grid-auto-rows: auto;

    P { margin: var(--gridunit-medium) 0 5px 0; text-transform: uppercase; color: var(--color-primary-500); font-size: 14px; line-height: 20px; letter-spacing: 0.04em; }

    &__group { padding: 0 8px; margin: 0 -8px; box-sizing: border-box; overflow: hidden; }

    &__list {
      margin: 0 -8px;

      > LI {
        A {
          display: block; padding: 4px 8px; box-sizing: border-box; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; text-decoration: none; border-radius: var(--radius-small);

          &.highlight,
          &:hover {
            background-color: var(--color-primary-50);
          }
        }
      }
    }
  }

  @include mobile {
    flex: 1 1 auto; display: flex; flex-direction: column;
  }
  @include not-mobile {
    position: relative; flex: 1 1 auto; max-width: 600px; margin: 0 auto;
  }

  &.is-overlay {
    background-color: var(--color-white); display: flex; flex: 1 1 auto; min-height: 100%;

    @include not-mobile { flex-direction: column; margin: 100px auto; min-height: 0; }
  }

  &.is-overlay & {
    &__header {
      background-color: var(--color-neutral-50); color: var(--color-black); padding: 13px;

      &__close { display: block; }
    }

    &__clear {
      width: 24px !important; height: 24px !important; flex: 0 0 24px; background-color: var(--color-link); border-radius: 50%; align-self: center;

      svg { width: 16px; height: 16px; color: var(--color-white); }
    }

    &__form {
      &:before { border-radius: 0; background-color: transparent; border-bottom: 1px solid var(--color-neutral-200); box-shadow: none; }
    }

    &__results {
      position: relative; flex: 1 1 auto; overflow: hidden; overflow-y: auto; @include scrollbars; box-shadow: none; border-radius: 0; max-height: none; left: 0; right: 0; top: 0; bottom: 0;
      display: block;
    }
  }

}