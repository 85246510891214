@import "@/styles/mixins";

:import("@/components/ui/LayoutOverlay/OverlayTitle/styles.module.scss") {
  overlay-title: overlay-title;
}

.nonidealstate {
  flex: 1 1 auto; flex-direction: column; text-align: center; justify-content: center; position: relative; @include default-margin;
  display: flex; flex-wrap: nowrap; align-items: center; box-sizing: border-box;

  &__inner { display: flex; justify-content: center; align-items: center; position: relative; width: 100%; }

  &__spacer { flex: 1 0 auto; }

  &__wrapper { flex: 0 0 auto; }

  &__icon {
    flex: 0 0 200px; line-height: 0; margin-right: 0; z-index: 1; display: flex; align-items: center; justify-content: center; width: 200px; height: 200px; position: relative;

    IMG { object-fit: cover; object-position: 50% 50%; }
  }

  &__content { flex: 0 0 auto; z-index: 1; font-weight: var(--medium); }

  &__title { font-size: 24px; line-height: normal; margin: 0; max-width: 400px; text-wrap: balance; }

  &__description { margin: 0.5em auto 0 auto; font-size: 18px; line-height: 26px; max-width: 400px; }

  &__addon { width: 100%; flex: 0 0 auto; align-items: flex-end; justify-content: center; text-align: center; display: flex; box-sizing: border-box; }

  &.layout-horizontal & {
    @include not-mobile {
      &__wrapper {
        display: flex; flex-direction: row-reverse; flex-wrap: nowrap; gap: var(--gridunit); width: 100%; align-items: center; justify-content: center;
      }

      &__inner { flex: 0 1 auto; width: auto; }

      &__content { flex: 0 1 auto; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 16px; }

      &__title { font-size: 18px; text-transform: none; margin: 0; max-width: 100%; font-weight: var(--medium); }
    }
  }

  @at-root .overlay-title + & { margin-top: 0; }

  &:first-child { margin-top: 0; }
}
